import React from "react";

import LotBids from "./LotBids";
import LotThumbnail from "./LotThumbnail";

interface Props {
  lot: Catalog.Lot;
  strings: Record<string, string>;
  locale: Locale;
  auctionType: string;
  status: string;
}

export default function Lot(props: Props) {
  const { locale, lot, strings } = props;

  const lotUrl = `/${locale}/lots/${lot.id}`;
  return (
    <a className="lot" href={lotUrl}>
      <LotThumbnail lot={lot} />
      <div className="content">
        {lot.catalog_number && (
          <div className="catalog-number">{lot.catalog_number}</div>
        )}
        <div className="artist-and-title">
          {lot.artist_name && (
            <span className="artist-name">
              {lot.artist_name}
              {lot.artist_lifespan && (
                <span className="lifespan">({lot.artist_lifespan})</span>
              )}
            </span>
          )}
          {!lot.artist_name && lot.object_type && (
            <span className="object-type">{lot.object_type}</span>
          )}
          <span className="title">
            {lot.title}
            {lot.year && <span className="year">({lot.year})</span>}
          </span>
        </div>
        {lot.info && (
          <div
            className="info"
            dangerouslySetInnerHTML={{ __html: lot.info }}></div>
        )}
        {lot.estimates && (
          <div className="estimates">
            {strings.estimate}
            <br />
            {lot.estimates.map((e) => (
              <span className="estimate" key={e}>
                {e}
              </span>
            ))}
          </div>
        )}
      </div>
      <LotBids {...props} />
    </a>
  );
}
