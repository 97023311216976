import React, { useState } from "react";
import Select from "react-select";
import csrfToken from "../../lib/csrfToken";

type BidOption = { value: number; label: string };

type Props = {
  customer_token: string;
  locale: Locale;
  loggedIn: boolean;
  lot: Lot.Lot;
  routes: Lot.Routes;
  strings: Lot.Localizations;
};

function formatBid(number: number) {
  return `NOK ${number}`;
}

export default function BidForm(props: Props) {
  const { lot, strings } = props;

  const [bidSequence] = useState(lot.bid_sequence);
  const bidOptions: BidOption[] = bidSequence.map((b) => {
    return { value: b, label: formatBid(b) };
  });

  const [bid, setBid] = useState(bidOptions[0]);

  const handleChange = (newBid: BidOption) => {
    setBid(newBid);
  };

  const validBid = () => {
    return bid.value >= lot.bid_sequence[0];
  };

  return (
    <form className="bid-form" action={props.routes.online_bids} method="POST">
      <h3>{strings.online_bids.make_a_bid}</h3>
      <input name="authenticity_token" type="hidden" value={csrfToken()} />
      <div className="field">
        <label>{strings.online_bids.choose_next_max_bid}</label>
        <Select
          name="online_bid[bid]"
          options={bidOptions}
          onChange={handleChange}
          value={bid}
        />
      </div>
      <div className="buttons">
        <button disabled={!validBid()} type="submit">
          {strings.online_bids.confirm_bid}
        </button>
      </div>
      <p
        dangerouslySetInnerHTML={{
          __html: strings.online_bids.accept_conditions_html
        }}
      />
    </form>
  );
}
