import React from "react";

import OnlineCta from "./OnlineCta";
import LiveCta from "./LiveCta";

type Props = {
  customer_token: string;
  recaptcha_key: string;
  locale: Locale;
  loggedIn: boolean;
  lot: Lot.Lot;
  routes: Lot.Routes;
  strings: Lot.Localizations;
};

export default function Cta(props: Props) {
  if (props.lot.auction.auction_type === "online_auction") {
    return <OnlineCta {...props} />;
  } else {
    return <LiveCta {...props} />;
  }
}
