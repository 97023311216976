import React from "react";
import CheckBoxField from "../CheckBoxField";
import TextField from "../TextField";

type Props = {
  handleChange: (attr: string) => (evt: React.ChangeEvent) => void;
  onSubmit: (evt: React.MouseEvent) => Promise<void>;
  validateData: (state: Partial<BidsRegistration.AdvanceBidData>) => boolean;
  state: BidsRegistration.AdvanceBidData;
  strings: BidsRegistration.Localizations;
};

export default function AdvanceBidForm(props: Props) {
  const formStrings = props.strings.advance_bid;
  const labels = props.strings.labels.advance_bid;

  const textField = (
    attr: keyof BidsRegistration.AdvanceBidData,
    additionalProps = {}
  ) => {
    return (
      <TextField
        {...additionalProps}
        prefix="advance_bid"
        name={attr}
        label={labels[attr]}
        onChange={props.handleChange(attr)}
        value={props.state[attr] as string}
      />
    );
  };

  const handleSubmit = (evt: React.MouseEvent) => {
    void props.onSubmit(evt);
  };

  return (
    <form>
      <div
        className="intro"
        dangerouslySetInnerHTML={{ __html: formStrings.intro_html }}
      />
      {textField("bid")}
      {textField("name")}
      {textField("email", { type: "email" })}
      {textField("phone")}
      <div
        className="billing-info"
        dangerouslySetInnerHTML={{ __html: formStrings.billing_info_html }}
      />
      {textField("billing_name", { placeholder: props.state.name })}
      {textField("billing_address")}
      {textField("billing_postal_code")}
      {textField("billing_postal_area")}
      {textField("billing_country")}
      <CheckBoxField
        prefix="advance_bid"
        name="accept_conditions"
        label={labels["accept_conditions_html"]}
        onChange={props.handleChange("accept_conditions")}
        value={props.state["accept_conditions"]}
      />
      <CheckBoxField
        prefix="advance_bid"
        name="consent"
        label={labels["consent"]}
        onChange={props.handleChange("consent")}
        value={props.state["consent"]}
      />
      <div
        className="note"
        dangerouslySetInnerHTML={{ __html: formStrings.note_html }}
      />
      <button
        disabled={!props.validateData(props.state)}
        type="submit"
        onClick={handleSubmit}
        className="submit-button">
        {formStrings.button_label}
      </button>
    </form>
  );
}
