import React from "react";

type Props = {
  address: CustomerAddress.Address;
  dispatch: React.Dispatch<CustomerAddress.Action>;
  strings: CustomerAddress.Localizations;
};

function addressLabel(address: CustomerAddress.Address) {
  return address.address.trim().split(/\n/)[0];
}

export default function ListItem(props: Props) {
  const { address, dispatch, strings } = props;

  const handleDelete = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "delete", payload: address });
  };

  const handleEdit = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "edit", payload: address });
  };

  return (
    <tr>
      <td>{addressLabel(address)}</td>
      <td className="actions">
        <a href="#" onClick={handleEdit}>
          {strings.edit}
        </a>
        <a href="#" onClick={handleDelete}>
          {strings.delete}
        </a>
      </td>
    </tr>
  );
}
