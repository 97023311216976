import React, { useEffect, useState } from "react";

import useFilter, { filterLots } from "./Catalog/useFilter";
import Header from "./Catalog/Header";
import Filter from "./Catalog/Filter";
import Lots from "./Catalog/Lots";

interface Props {
  auction: Catalog.Auction;
  strings: Record<string, string>;
  live_url: string;
  locale: Locale;
  results_url: string;
}

interface LiveResponse {
  lots: Catalog.Lot[];
}

function refreshAuction(
  url: string | null,
  callback: (response: LiveResponse) => void
) {
  if (url) {
    const req = new XMLHttpRequest();
    req.open("GET", url);
    req.addEventListener("load", function () {
      const response = JSON.parse(req.responseText) as { data: LiveResponse };
      callback(response.data);
    });
    req.send();
  }
}

export default function Catalog(props: Props) {
  const { auction, strings } = props;

  const [showFilter, setShowFilter] = useState(false);
  const [view, setView] = useState("list");
  const [filterState, filterDispatch] = useFilter(auction.lots);

  const toggleFilter = () => setShowFilter(!showFilter);

  // Refresh live auction
  useEffect(() => {
    const interval = setInterval(() => {
      refreshAuction(props.live_url, (json: LiveResponse) => {
        filterDispatch({ type: "updateLots", payload: json.lots });
      });
    }, 5000);
    return () => clearInterval(interval);
  }, [filterDispatch, props.live_url]);

  const classNames = ["catalog", view];
  if (showFilter) {
    classNames.push("show-filter");
  }

  return (
    <div className={classNames.join(" ")}>
      <Header
        auction={auction}
        results_url={props.results_url}
        view={view}
        setView={setView}
        showFilter={showFilter}
        toggleFilter={toggleFilter}
        strings={strings}
      />
      <Filter
        state={filterState}
        showStatus={auction.auction_type == "online_auction"}
        dispatch={filterDispatch}
        strings={strings}
      />
      <Lots
        auction={auction}
        lots={filterLots(filterState)}
        locale={props.locale}
        strings={strings}
      />
    </div>
  );
}
