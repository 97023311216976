import React, { useState } from "react";

type Props = {
  address: CustomerAddress.Address;
  dispatch: React.Dispatch<CustomerAddress.Action>;
  strings: CustomerAddress.Localizations;
};

export default function Editor(props: Props) {
  const { dispatch, strings } = props;
  const [address, setAddress] = useState({ ...props.address });

  const handleCancel = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "cancel" });
  };

  const handleSave = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "save", payload: address });
  };

  const handleChange =
    (attr: string) =>
    (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setAddress({ ...address, [attr]: evt.target.value });
    };

  const validAddress = () => {
    return (
      address.address.trim().length > 0 &&
      address.postal_code.trim().length > 0 &&
      address.country.trim().length > 0
    );
  };

  return (
    <div className="editor">
      <fieldset>
        <div className="field text-field">
          <label htmlFor="address">{strings.address}</label>
          <textarea
            name="address"
            onChange={handleChange("address")}
            value={address.address}
          />
        </div>
      </fieldset>
      <fieldset>
        <div className="field text-field">
          <label htmlFor="postalCode">{strings.postal_code}</label>
          <input
            name="postalCode"
            onChange={handleChange("postal_code")}
            type="text"
            value={address.postal_code}
          />
        </div>
      </fieldset>
      <fieldset>
        <div className="field text-field">
          <label htmlFor="postalArea">{strings.postal_area}</label>
          <input
            name="postalArea"
            onChange={handleChange("postal_area")}
            type="text"
            value={address.postal_area}
          />
        </div>
      </fieldset>
      <fieldset>
        <div className="field text-field">
          <label htmlFor="country">{strings.country}</label>
          <input
            name="country"
            onChange={handleChange("country")}
            type="text"
            value={address.country}
          />
        </div>
      </fieldset>
      <div className="buttons">
        <button onClick={handleSave} disabled={!validAddress()}>
          {address.new ? strings.add : strings.save}
        </button>
        <button onClick={handleCancel}>{strings.cancel}</button>
      </div>
    </div>
  );
}
