import React, { useReducer } from "react";
import { v4 as uuidv4 } from "uuid";

import Editor from "./CustomerAddresses/Editor";
import List from "./CustomerAddresses/List";
import SelectAddresses from "./CustomerAddresses/SelectAddresses";
import Serialize from "./CustomerAddresses/Serialize";

type Props = {
  addresses: CustomerAddress.Address[];
  strings: CustomerAddress.Localizations;
  primary_address_id: number;
  billing_address_id: number;
  shipping_address_id: number;
};

function newAddress(): CustomerAddress.Address {
  return {
    id: null,
    address: "",
    postal_code: "",
    postal_area: "",
    country: "",
    handle: uuidv4(),
    new: true
  };
}

function saveAddress(
  state: CustomerAddress.State,
  address: CustomerAddress.Address
) {
  let addresses = state.addresses;
  if (addresses.filter((a) => a.handle == address.handle).length > 0) {
    addresses = addresses.map((a) => {
      if (a.handle == address.handle) {
        return address;
      } else {
        return a;
      }
    });
  } else {
    addresses = addresses.concat({ ...address, new: false });
  }
  return { ...state, addresses: addresses, editing: null };
}

function reducer(
  state: CustomerAddress.State,
  action: CustomerAddress.Action
): CustomerAddress.State {
  switch (action.type) {
    case "cancel":
      return { ...state, editing: null };
    case "delete":
      return {
        ...state,
        addresses: state.addresses.filter(
          (a) => a.handle != action.payload.handle
        ),
        deleted: [...state.deleted, action.payload]
      };
    case "edit":
      return { ...state, editing: action.payload };
    case "new":
      return { ...state, editing: newAddress() };
    case "save":
      return saveAddress(state, action.payload);
    case "select":
      return {
        ...state,
        selected: {
          ...state.selected,
          [action.payload.name]: action.payload.handle
        }
      };
    default:
      return state;
  }
}

function findHandleById(addresses: CustomerAddress.Address[], id: number) {
  const address = addresses.filter((a) => a.id == id)[0];

  return address ? address.handle : null;
}

function initialState(props: Props) {
  const addresses = (props.addresses || []).map((a) => {
    return { ...a, handle: uuidv4() };
  });

  const state = {
    addresses: addresses,
    deleted: [],
    editing: null,
    selected: {
      primary: findHandleById(addresses, props.primary_address_id),
      billing: findHandleById(addresses, props.billing_address_id),
      shipping: findHandleById(addresses, props.shipping_address_id)
    }
  };

  return ensureSelectedReducer(state);
}

function ensureSelectedReducer(
  state: CustomerAddress.State,
  action?: CustomerAddress.Action
): CustomerAddress.State {
  let nextState = state;
  if (action) {
    nextState = reducer(state, action);
  }

  const handles = nextState.addresses.map((a) => a.handle);
  const selected = { ...nextState.selected };

  // Remove deleted addresses
  Object.keys(selected).forEach((key) => {
    if (handles.indexOf(selected[key] as string) == -1) {
      selected[key] = null;
    }
  });

  if (nextState.addresses.length > 0) {
    selected.primary = selected.primary || nextState.addresses[0].handle;
    selected.billing = selected.billing || selected.primary;
    selected.shipping = selected.shipping || selected.primary;
  }

  return {
    ...nextState,
    deleted: nextState.deleted.filter((a) => a.id),
    selected: selected
  };
}

export default function CustomerAddresses(props: Props) {
  const [state, dispatch] = useReducer(
    ensureSelectedReducer,
    props,
    initialState
  );

  return (
    <div className="customer-addresses">
      {!state.editing && (
        <List
          addresses={state.addresses}
          dispatch={dispatch}
          strings={props.strings}
        />
      )}
      {!state.editing && state.addresses.length > 0 && (
        <SelectAddresses
          state={state}
          dispatch={dispatch}
          strings={props.strings}
        />
      )}
      {state.editing && (
        <Editor
          address={state.editing}
          dispatch={dispatch}
          strings={props.strings}
        />
      )}
      <Serialize state={state} />
    </div>
  );
}
