import React, { useState } from "react";
import csrfToken from "../lib/csrfToken";

interface Props {
  url: string;
  strings: Record<string, string>;
  query: string;
}

export default function SaveSearch({ url, strings, query }: Props) {
  const [progress, setProgress] = useState<number>(1);
  const [email, setEmail] = useState<string>("");
  const [consent, setConsent] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>(query);

  const changeConsent = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setConsent(evt.target.checked);
  };

  const changeEmail = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(evt.target.value);
  };

  const changeQuery = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(evt.target.value);
  };

  const formData = () => ({
    saved_search: {
      query: searchQuery,
      email: email,
      gdpr_consent: consent
    }
  });

  const isValid = () => searchQuery && email && consent;

  const open = (evt: React.MouseEvent) => {
    evt.preventDefault();
    setProgress(2);
  };

  const send = (evt: React.MouseEvent) => {
    evt.preventDefault();

    const xhr = new XMLHttpRequest();
    xhr.open("POST", url, true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.setRequestHeader("X-CSRF-Token", csrfToken());
    xhr.onload = () => {
      if (xhr.readyState === 4 && xhr.status === 200) {
        setProgress(3);
      }
    };
    xhr.send(JSON.stringify(formData()));
  };

  const renderForm = () => (
    <form>
      <p>{strings.intro}</p>
      <div className="columns">
        <div className="column">
          <div className="field">
            <label htmlFor="saved_search_query">{strings.query}</label>
            <input
              type="text"
              id="saved_search_query"
              name="saved_search[query]"
              onChange={changeQuery}
              value={searchQuery}
            />
          </div>
        </div>
        <div className="column">
          <div className="field">
            <label htmlFor="saved_search_email">{strings.email}</label>
            <input
              type="text"
              id="saved_search_email"
              name="saved_search[email]"
              onChange={changeEmail}
              value={email}
            />
          </div>
        </div>
      </div>
      <div className="field check-boxes">
        <label htmlFor="saved_search_consent">
          <input
            type="checkbox"
            id="saved_search_consent"
            name="saved_search[consent]"
            checked={consent}
            onChange={changeConsent}
          />
          {strings.gdprConsent}
        </label>
      </div>
      <button type="submit" disabled={!isValid()} onClick={send}>
        {strings.send}
      </button>
    </form>
  );

  return (
    <div className="save-search">
      {progress === 1 && <button onClick={open}>{strings.subscribe}</button>}
      {progress === 2 && renderForm()}
      {progress === 3 && (
        <div className="box">
          <p>{strings.thanks}</p>
        </div>
      )}
    </div>
  );
}
