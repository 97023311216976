import React, { useEffect, useState } from "react";

type Article = {
  id: number;
  name: string;
  excerpt: string;
  image?: string;
  url: string;
};

interface Props {
  articles: Article[];
  read_more: string;
}

function imageStyle(article: Article) {
  return article.image ? { backgroundImage: `url('${article.image}')` } : {};
}

export default function FeaturedNews({ articles, read_more }: Props) {
  const [articleIndex, setArticleIndex] = useState(0);

  useEffect(() => {
    if (articles.length < 2) return;

    const interval = setInterval(() => {
      setArticleIndex((prevIndex) => (prevIndex + 1) % articles.length);
    }, 9900);

    return () => clearInterval(interval);
  }, [articles]);

  const currentArticle = () => {
    return articles[articleIndex];
  };

  const articleClass = (article: Article) => {
    return article === currentArticle() ? "" : "faded";
  };

  const renderImage = () => {
    const article = currentArticle();
    return (
      <div className="hero-article" key={articleIndex}>
        <a href={article.url} className="article">
          <div className="background-image" style={imageStyle(article)} />
          <div className="gradient" />
          <div className="inner">
            <h1>{article.name}</h1>
          </div>
        </a>
      </div>
    );
  };

  return (
    <div className="featured-news">
      {renderImage()}
      <div className="inner">
        <div className="articles">
          {articles.map((article) => (
            <article key={article.id} className={articleClass(article)}>
              <h4>
                <a href={article.url}>{article.name}</a>
              </h4>
              {article.excerpt && (
                <div
                  className="excerpt"
                  dangerouslySetInnerHTML={{ __html: article.excerpt }}></div>
              )}
              <p className="read-more">
                <a href={article.url}>{read_more}</a>
              </p>
            </article>
          ))}
        </div>
      </div>
    </div>
  );
}
