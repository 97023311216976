import React from "react";

import BidsRegistration from "../BidsRegistration";
import SoldPrice from "./SoldPrice";

type Props = {
  recaptcha_key: string;
  lot: Lot.Lot;
  routes: Lot.Routes;
  strings: Lot.Localizations;
};

export default function LiveCta(props: Props) {
  const { lot, strings } = props;

  return (
    <div className="call-to-action">
      <h4>
        {lot.upcoming ? strings.coming_up_for_sale : strings.auctioned}{" "}
        {lot.auction.date}
      </h4>

      {lot.advance_bid && (
        <BidsRegistration
          recaptcha_key={props.recaptcha_key}
          routes={props.routes}
          strings={strings.registration}
        />
      )}

      <SoldPrice lot={lot} strings={strings} />
    </div>
  );
}
