import React, { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import csrfToken from "../lib/csrfToken";

interface Props {
  strings: Record<string, string>;
  url: string;
  recaptcha_key: string;
}

interface State {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  message: string;
  consent: boolean;
  submitted: boolean;
}

export default function ExpertVisitForm(props: Props) {
  const { executeRecaptcha } = useGoogleReCaptcha();

  const [state, setState] = useState<State>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    consent: false,
    submitted: false
  });

  const updateState = (newState: Partial<State>) => {
    setState({ ...state, ...newState });
  };

  const handleConsent = (evt: React.ChangeEvent<HTMLInputElement>) => {
    updateState({ consent: evt.target.checked });
  };

  const handleChange =
    (attr: string) =>
    (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      updateState({ [attr]: evt.target.value });
    };

  const isValid = () => {
    return state.firstName && state.lastName && state.email && state.consent;
  };

  const formData = () => {
    return {
      expert_visit_inquiry: {
        first_name: state.firstName,
        last_name: state.lastName,
        email: state.email,
        phone: state.phone,
        message: state.message,
        gdpr_consent: state.consent
      }
    };
  };

  const send = async (evt: React.MouseEvent) => {
    evt.preventDefault();

    const token = await executeRecaptcha("expert_visit_inquiry");

    const xhr = new XMLHttpRequest();
    xhr.open("POST", props.url, true);
    xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
    xhr.setRequestHeader("X-CSRF-Token", csrfToken());
    xhr.onload = () => {
      if (xhr.readyState == 4 && xhr.status == 200) {
        updateState({ submitted: true });
      }
    };
    xhr.send(
      JSON.stringify({ ...formData(), new_google_recaptcha_token: token })
    );
  };

  if (state.submitted) {
    return (
      <div
        className="submitted"
        dangerouslySetInnerHTML={{ __html: props.strings.thanks }}
      />
    );
  }

  const handleSend = (evt: React.MouseEvent) => {
    void send(evt);
  };

  return (
    <form className="expert-visit-form">
      <div className="field">
        <label htmlFor="inquiry_first_name">{props.strings.firstName}</label>
        <input
          type="text"
          id="inquiry_first_name"
          name="inquiry[first_name]"
          value={state.firstName}
          onChange={handleChange("firstName")}
        />
      </div>
      <div className="field">
        <label htmlFor="inquiry_last_name">{props.strings.lastName}</label>
        <input
          type="text"
          id="inquiry_last_name"
          name="inquiry[last_name]"
          value={state.lastName}
          onChange={handleChange("lastName")}
        />
      </div>
      <div className="field">
        <label htmlFor="inquiry_email">{props.strings.email}</label>
        <input
          type="text"
          id="inquiry_email"
          name="inquiry[email]"
          value={state.email}
          onChange={handleChange("email")}
        />
      </div>
      <div className="field">
        <label htmlFor="inquiry_phone">{props.strings.phone}</label>
        <input
          type="text"
          id="inquiry_phone"
          name="inquiry[phone]"
          value={state.phone}
          onChange={handleChange("phone")}
        />
      </div>
      <div className="field">
        <label htmlFor="inquiry_message">{props.strings.message}</label>
        <textarea
          id="inquiry_message"
          name="inquiry[message]"
          value={state.message}
          onChange={handleChange("message")}
        />
      </div>
      <div className="field check-boxes">
        <label htmlFor="inquiry_consent">
          <input
            type="checkbox"
            id="inquiry_consent"
            name="inquiry[consent]"
            checked={state.consent}
            onChange={handleConsent}
          />
          {props.strings.gdprConsent}
        </label>
      </div>
      <button type="submit" disabled={!isValid()} onClick={handleSend}>
        {props.strings.send}
      </button>
    </form>
  );
}
