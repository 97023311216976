import React from "react";

interface Props {
  label: string;
  name: string;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  value: boolean;
  prefix?: string;
}

export default function CheckBoxField(props: Props) {
  let id = props.name;
  let name = props.name;

  if (props.prefix) {
    id = `${props.prefix}_${id}`;
    name = `${props.prefix}[${name}]`;
  }

  return (
    <div className="field check-boxes">
      <label htmlFor={id}>
        <input name={name} type="hidden" value="0" />
        <input
          type="checkbox"
          id={id}
          name={name}
          value="1"
          checked={props.value ? true : false}
          onChange={props.onChange}
        />
        <span dangerouslySetInnerHTML={{ __html: props.label }} />
      </label>
    </div>
  );
}
