import React from "react";

type Props = {
  lot: Lot.Lot;
  strings: Lot.Localizations;
};

export default function CurrentPrice(props: Props) {
  const { lot, strings } = props;

  if (lot.current_price) {
    return (
      <div className="current-price">
        {strings.next_bid}
        <br />
        <big>
          <b>NOK {lot.next_bid}</b>
        </big>
      </div>
    );
  } else {
    return "";
  }
}
