import React from "react";

interface Props {
  tab: string;
  toggleTab: (name: string) => void;
  label: string;
  name: string;
}

export default function TabButton(props: Props) {
  const current = props.tab === props.name;

  const handleClick = (evt: React.MouseEvent) => {
    evt.preventDefault();
    props.toggleTab(props.name);
  };

  const classes = ["tab-button"];
  if (current) {
    classes.push("current");
  }

  return (
    <button className={classes.join(" ")} onClick={handleClick}>
      {props.label}
    </button>
  );
}
