import React from "react";

import ListItem from "./ListItem";

type Props = {
  addresses: CustomerAddress.Address[];
  dispatch: React.Dispatch<CustomerAddress.Action>;
  strings: CustomerAddress.Localizations;
};

export default function List(props: Props) {
  const { addresses, dispatch, strings } = props;

  const handleNew = (evt: React.MouseEvent) => {
    evt.preventDefault();
    dispatch({ type: "new" });
  };

  return (
    <div className="addresses">
      {addresses.length > 0 && (
        <table className="addresses list">
          <tbody>
            {addresses.map((a) => (
              <ListItem
                key={a.handle}
                address={a}
                dispatch={dispatch}
                strings={strings}
              />
            ))}
          </tbody>
        </table>
      )}
      <div className="buttons">
        <button onClick={handleNew}>{strings.add_new_address}</button>
      </div>
    </div>
  );
}
