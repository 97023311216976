import React from "react";

import useLot from "./Lot/useLot";
import Images from "./Lot/Images";
import Info from "./Lot/Info";
import Cta from "./Lot/Cta";

type Props = {
  customer_token: string;
  recaptcha_key: string;
  locale: Locale;
  lot: Lot.Lot;
  routes: Lot.Routes;
  strings: Lot.Localizations;
};

export default function Lot(props: Props) {
  const { strings } = props;

  const lot = useLot(props.lot, props.routes.backend, props.customer_token);

  return (
    <div className="lot">
      <Images images={lot.images} title={lot.title} strings={strings} />

      <div className="content">
        <Info lot={lot} strings={strings} />
        <Cta
          loggedIn={props.customer_token ? true : false}
          lot={lot}
          customer_token={props.customer_token}
          recaptcha_key={props.recaptcha_key}
          locale={props.locale}
          routes={props.routes}
          strings={strings}
        />
      </div>

      {(lot.video_embed || lot.body) && (
        <div className="more">
          {lot.video_embed && (
            <div
              className="video-embed"
              dangerouslySetInnerHTML={{ __html: lot.video_embed }}
            />
          )}
          {lot.body && (
            <div
              className="body"
              dangerouslySetInnerHTML={{ __html: lot.body }}
            />
          )}
        </div>
      )}
    </div>
  );
}
