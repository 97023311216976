import React from "react";

type Props = {
  lot: Lot.Lot;
  strings: Lot.Localizations;
};

function rawHtml(str: string) {
  return <div dangerouslySetInnerHTML={{ __html: str }} />;
}

export default function Info(props: Props) {
  const { lot, strings } = props;

  return (
    <div className="lot-info">
      {lot.artist_name && (
        <div className="artist-name">
          {lot.artist_name}
          {lot.artist_lifespan && (
            <span className="lifespan">({lot.artist_lifespan})</span>
          )}
        </div>
      )}

      {!lot.artist_name && lot.object_type && (
        <div className="object-type">{lot.object_type}</div>
      )}

      <div className="title">{lot.title}</div>

      {lot.info && <div className="info">{rawHtml(lot.info)}</div>}

      {lot.notes && <div className="notes">{rawHtml(lot.notes)}</div>}

      {lot.displayed && (
        <div className="displayed">
          <h6>{strings.exhibited}:</h6>
          {rawHtml(lot.displayed)}
        </div>
      )}

      {lot.provenance && (
        <div className="provenance">
          <h6>{strings.provenance}:</h6>
          {rawHtml(lot.provenance)}
        </div>
      )}

      {lot.estimates && (
        <p className="estimates">
          {strings.estimate}
          <br />
          {lot.estimates.map((e) => (
            <span key={e} className="estimate">
              {e}
            </span>
          ))}
        </p>
      )}
    </div>
  );
}
