import React from "react";

interface Props {
  action: "toggleArtist" | "toggleCategory";
  dispatch: Catalog.FilterDispatch;
  options: Array<string>;
  selected: Array<string>;
}

export default function ToggleItems(props: Props) {
  const handleToggle = (option: string) => () => {
    props.dispatch({ type: props.action, payload: option });
  };

  return (
    <div className="toggle-items">
      {props.options.map((o) => (
        <label key={o}>
          <input
            type="checkbox"
            onChange={handleToggle(o)}
            checked={props.selected.indexOf(o) !== -1}
          />
          {o}
        </label>
      ))}
    </div>
  );
}
