import React from "react";

import RelativeTime from "../RelativeTime";

interface Props {
  lot: Catalog.Lot;
  strings: Record<string, string>;
  locale: Locale;
  auctionType: string;
  status: string;
}

export default function LotBids(props: Props) {
  const { auctionType, locale, lot, status, strings } = props;

  const soldPrice = () => {
    if (auctionType == "online_auction" && lot.ended && lot.online_bids) {
      return lot.current_price;
    } else if (["live", "finished", "closed"].indexOf(status) != -1) {
      return lot.sold && lot.sold_price;
    }
    return null;
  };

  const showCurrentPrice = () => {
    return (
      auctionType == "online_auction" &&
      lot.current_price &&
      !soldPrice() &&
      !showUnsold()
    );
  };

  const showEndsAt = () => {
    if (!lot.ends_at || lot.ended) {
      return false;
    }

    return new Date(lot.ends_at) > new Date();
  };

  const showUnsold = () => {
    if (auctionType == "online_auction") {
      return lot.ended && !lot.online_bids && !lot.sold;
    } else if (auctionType == "auction") {
      return !lot.sold && (status == "finished" || status == "closed");
    }
    return false;
  };

  return (
    <div className="bids">
      {soldPrice() && (
        <div className="sold">
          {strings.sold_for}
          <br />
          <b>
            NOK {soldPrice()}
            {lot.sold_reservation && " " + strings.to_be_approved}
          </b>
        </div>
      )}
      {showCurrentPrice() && (
        <div className="current-price">
          {strings.next_bid}
          <br />
          <b>NOK {lot.next_bid}</b>
        </div>
      )}
      {showUnsold() && <div className="sold">{strings.unsold}</div>}
      {showEndsAt() && (
        <div className="ends-at">
          {strings.ends_in}
          <br />
          <b>
            <RelativeTime locale={locale} time={lot.ends_at} />
          </b>
        </div>
      )}
    </div>
  );
}
