import React from "react";

import { formatDateTime } from "../../lib/dates";
import useBrowser from "../../hooks/useBrowser";
import RelativeTime from "../RelativeTime";
import BidForm from "./BidForm";
import BidLog from "./BidLog";
import BidStatus from "./BidStatus";
import CurrentPrice from "./CurrentPrice";
import SoldPrice from "./SoldPrice";

type Props = {
  customer_token: string;
  locale: Locale;
  loggedIn: boolean;
  lot: Lot.Lot;
  routes: Lot.Routes;
  strings: Lot.Localizations;
};

export default function OnlineCta(props: Props) {
  const { loggedIn, lot, routes, strings } = props;

  const browser = useBrowser();

  const showBidForm = loggedIn && browser;

  if (lot.ended) {
    return (
      <div className="call-to-action online">
        <SoldPrice lot={lot} strings={strings} />

        {loggedIn && <BidStatus lot={lot} strings={strings} />}

        {lot.bid_log.length > 0 && (
          <BidLog locale={props.locale} lot={lot} strings={strings} />
        )}
      </div>
    );
  } else if (!lot.show_online_bids) {
    return (
      <div className="call-to-action online">
        <CurrentPrice lot={lot} strings={strings} />
      </div>
    );
  } else {
    return (
      <div className="call-to-action online">
        <CurrentPrice lot={lot} strings={strings} />

        {lot.ends_at && (
          <div className="ends-at">
            {strings.ends_in}
            <br />
            <big>
              <b>
                <RelativeTime locale={props.locale} time={lot.ends_at} />
              </b>{" "}
              ({formatDateTime(props.locale, lot.ends_at)})
            </big>
            <br />
            {strings.online_bid_extension_info}
          </div>
        )}

        {!loggedIn && (
          <div className="login-button">
            <a href={routes.login} className="button primary">
              {strings.login_to_bid}
            </a>
          </div>
        )}

        {loggedIn && <BidStatus lot={lot} strings={strings} />}
        {showBidForm && <BidForm {...props} />}

        <BidLog locale={props.locale} lot={lot} strings={strings} />
      </div>
    );
  }
}
