import React from "react";

import RelativeTime from "../RelativeTime";
import Results from "./Results";

interface Props {
  locale: Locale;
  lot: CustomerLot.Lot;
  strings: CustomerLot.Localizations;
}

export default function Row(props: Props) {
  const { locale, lot, strings } = props;

  const url = `/${locale}/lots/${lot.id}`;

  return (
    <tr>
      <td>
        <span className="auction-name">{lot.auction.name}</span>
        <span className="auction-date">{lot.auction.date}</span>
      </td>
      <td>
        <a href={url} className="lot-link">
          {lot.catalog_number && (
            <span className="catalog-number">
              {strings.cat_number} {lot.catalog_number}
            </span>
          )}
          {lot.artist_name && <span className="artist">{lot.artist_name}</span>}
          {!lot.artist_name && lot.object_type && (
            <span className="object-type">{lot.object_type}</span>
          )}
          {lot.title && <span className="title">{lot.title}</span>}
        </a>
      </td>
      <td>
        {lot.ended && <span>{strings.ended}</span>}
        {!lot.ended && (
          <span>
            <RelativeTime locale={locale} time={lot.ends_at} />
            {strings.left}
          </span>
        )}
      </td>
      <td>NOK {lot.current_price}</td>
      <td>
        <Results lot={lot} strings={strings} url={url} />
      </td>
    </tr>
  );
}
