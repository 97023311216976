import React from "react";

interface Props {
  image: Lot.Image;
  strings: Lot.Localizations;
  title: string;
}

function imageClass(image: Lot.Image) {
  if (image.height > image.width) {
    return "portrait";
  } else {
    return "landscape";
  }
}

export default function Image(props: Props) {
  const { image, strings, title } = props;
  return (
    <div className="image-wrapper">
      <div className="image">
        <img src={image.medium} className={imageClass(image)} alt={title} />
      </div>
      <a className="press-photo" href={image.original}>
        {strings.press_photo}
      </a>
    </div>
  );
}
